import {
  FileExcelOutlined,
  RetweetOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Descriptions,
  DescriptionsProps,
  Flex,
  Input,
  Select,
  Table,
  TableColumnsType,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { saveAs } from 'file-saver';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

import {
  useGetGroupsQuery,
  useGetPlanTypesQuery,
  useGetShopAccountCountQuery,
  useGetShopAccountsQuery,
  useGetShopAccountStatusQuery,
} from '../../generated/api/axios-client/ShopAccountControllerQuery';

const UsersPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { page } = useParams<{ page: string }>();

  const limit = 10;
  const currentPage = page ? parseInt(page) : 1;
  const offset = (currentPage - 1) * limit;
  const [term, setTerm] = useState<string>('');
  const [memo, setMemo] = useState<string>(searchParams.get('memo') || '');

  const [dateRangeKey, setDateRangeKey] = useState<
    'createdAt' | 'latestPaidAt'
  >('createdAt');

  useEffect(() => {
    if (searchParams.has('createdAt')) {
      setDateRangeKey('createdAt');
    } else if (searchParams.has('latestPaidAt')) {
      setDateRangeKey('latestPaidAt');
    }
  }, [searchParams]);

  const [createdAtFrom, createdAtTo] = searchParams.get('createdAt')
    ? (searchParams.get('createdAt')!.split('~') as [string, string])
    : [undefined, undefined];

  const [latestPaidAtFrom, latestPaidAtTo] = searchParams.get('latestPaidAt')
    ? (searchParams.get('latestPaidAt')!.split('~') as [string, string])
    : [undefined, undefined];

  const group = searchParams.get('group');
  const upcoming_payment = searchParams.get('upcoming_payment');
  const status = searchParams.get('status');

  const { data, isLoading } = useGetShopAccountsQuery({
    offset,
    limit,
    term: searchParams.get('term') || undefined,
    memo: searchParams.get('memo') || undefined,
    createdAtFrom,
    createdAtTo,
    latestPaidAtFrom,
    latestPaidAtTo,
    groupId: group ? parseInt(group) : undefined,
    plan: searchParams.get('plan') || undefined,
    upcoming_payment: upcoming_payment ? parseInt(upcoming_payment) : undefined,
    status: status ? parseInt(status) : undefined,
  });

  const { data: count } = useGetShopAccountCountQuery({
    term: searchParams.get('term') || undefined,
    memo: searchParams.get('memo') || undefined,
    createdAtFrom,
    createdAtTo,
    latestPaidAtFrom,
    latestPaidAtTo,
    groupId: group ? parseInt(group) : undefined,
    plan: searchParams.get('plan') || undefined,
    upcoming_payment: upcoming_payment ? parseInt(upcoming_payment) : undefined,
    status: status ? parseInt(status) : undefined,
  });

  const { data: groups } = useGetGroupsQuery();
  const { data: plans } = useGetPlanTypesQuery();
  const { data: statusList } = useGetShopAccountStatusQuery();

  const updateSearchParams = (queryKey: string, value: string) => {
    const newParams = new URLSearchParams(window.location.search);

    if (value) {
      newParams.set(queryKey, value);
    } else {
      newParams.delete(queryKey);
    }

    if (queryKey === 'createdAt') {
      newParams.delete('latestPaidAt');
    } else if (queryKey === 'latestPaidAt') {
      newParams.delete('createdAt');
    }

    navigate({
      pathname: '/users',
      search: newParams.toString(),
    });
  };

  const useDebouncedSearch = (queryKey: string) => {
    return useCallback(
      debounce((value: string) => {
        updateSearchParams(queryKey, value);
      }, 500),
      [searchParams, queryKey],
    );
  };

  const updateTermSearchParams = useDebouncedSearch('term');
  const updateMemoSearchParams = useDebouncedSearch('memo');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTerm(value);
    updateTermSearchParams(value);
  };

  const handleMemoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMemo(value);
    updateMemoSearchParams(value);
  };

  const columns: TableColumnsType = [
    {
      title: '쇼핑몰 이름',
      dataIndex: 'mallName',
    },
    {
      title: '쇼핑몰 ID',
      dataIndex: 'mallId',
    },
    {
      title: '대표 로그인 이메일',
      dataIndex: 'email',
    },
    {
      title: '가입일',
      dataIndex: 'createdAt',
    },
    {
      title: '그룹',
      dataIndex: 'group',
    },
    {
      title: '플랜',
      dataIndex: 'plan',
    },
    {
      title: '최근 결제일',
      dataIndex: 'latestPaidAt',
    },
    {
      title: '계정 상태',
      dataIndex: 'status',
    },
    {
      title: '기타 메모',
      dataIndex: 'memo',
    },
  ];

  const dataSource = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((shopAccount) => ({
      key: shopAccount.id,
      mallName: shopAccount.mallName,
      mallId: shopAccount.mallId,
      email: shopAccount.loginMail,
      createdAt: shopAccount.createdAt,
      memo: shopAccount.memo,
      plan: shopAccount.plan.name,
      group: shopAccount.group?.name,
      latestPaidAt: shopAccount.latestPaidAt,
      status: shopAccount.status?.name,
    }));
  }, [data]);

  const handleDownloadExcel = useCallback(() => {
    const excelData = dataSource.map((item) => ({
      ID: item.key,
      '쇼핑몰 이름': item.mallName,
      '쇼핑몰 ID': item.mallId,
      '대표 로그인 이메일': item.email,
      가입일: item.createdAt,
      '기타 메모': item.memo,
      플랜: item.plan,
      그룹: item.group,
      '최근 결제일': item.latestPaidAt,
      '계정 상태': item.status,
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'users.xlsx');
  }, [data]);

  const filters = useMemo<DescriptionsProps['items']>(() => {
    let groupOptions = [{ label: '전체', value: '' }];
    let planOptions = [{ label: '전체', value: '' }];
    let statusOptions = [{ label: '전체', value: '' }];

    if (groups) {
      groupOptions = [
        ...groupOptions,
        ...groups.map((group) => ({
          value: group.id.toString(),
          label: group.name,
        })),
      ];
    }

    if (plans) {
      planOptions = [
        ...planOptions,
        ...plans.map((plan) => ({
          value: plan.id.toString(),
          label: plan.name,
        })),
      ];
    }

    if (statusList) {
      statusOptions = [
        ...statusOptions,
        ...statusList.map((stat) => ({
          value: stat.id.toString(),
          label: stat.name,
        })),
      ];
    }

    const dateRangeParam =
      searchParams.get('createdAt') || searchParams.get('latestPaidAt');

    let dateRange: [Dayjs, Dayjs] | undefined = undefined;

    if (dateRangeParam) {
      const [from, to] = dateRangeParam.split('~');
      dateRange = [dayjs(from), dayjs(to)];
    }

    const filters = [
      {
        key: '1',
        label: (
          <Select
            style={{ width: 140 }}
            value={dateRangeKey}
            onChange={(value) => {
              setDateRangeKey(value);

              if (dateRange) {
                const [from, to] = dateRange;
                const formattedFrom = from.format('YYYY-MM-DD');
                const formattedTo = to.format('YYYY-MM-DD');
                const range = `${formattedFrom}~${formattedTo}`;

                updateSearchParams(value, range);
              }
            }}
            options={[
              { value: 'createdAt', label: '가입일' },
              { value: 'latestPaidAt', label: '최근 결제일' },
            ]}
          />
        ),
        children: (
          <Flex>
            <DatePicker.RangePicker
              onChange={(dates: [Dayjs | null, Dayjs | null] | null) => {
                if (dates && dates[0] && dates[1]) {
                  const [from, to] = dates;
                  const formattedFrom = from.format('YYYY-MM-DD');
                  const formattedTo = to.format('YYYY-MM-DD');
                  const range = `${formattedFrom}~${formattedTo}`;

                  updateSearchParams(dateRangeKey, range);
                }

                if (!dates) {
                  updateSearchParams(dateRangeKey, '');
                }
              }}
              value={dateRange}
            />
          </Flex>
        ),
        span: 4,
      },
      {
        key: '2',
        label: '그룹',
        children: (
          <Select
            style={{ width: 240 }}
            defaultValue="전체"
            value={searchParams.get('group') || ''}
            options={groupOptions}
            onSelect={(value) => {
              updateSearchParams('group', value);
            }}
          />
        ),
        span: 2,
      },
      {
        key: '3',
        label: '플랜',
        children: (
          <Select
            style={{ width: 240 }}
            defaultValue="전체"
            value={searchParams.get('plan') || ''}
            options={planOptions}
            onSelect={(value) => {
              updateSearchParams('plan', value);
            }}
          />
        ),
        span: 2,
      },
      {
        key: '4',
        label: '결제 예정',
        children: (
          <Select
            style={{ width: 240 }}
            defaultValue="전체"
            options={[
              { label: '전체', value: '' },
              { value: '1', label: '전환 예정' },
            ]}
            value={searchParams.get('upcoming_payment') || ''}
            onSelect={(value) => {
              updateSearchParams('upcoming_payment', value);
            }}
          />
        ),
        span: 2,
      },
      {
        key: '5',
        label: '계정 상태',
        children: (
          <Select
            style={{ width: 240 }}
            defaultValue="전체"
            options={statusOptions}
            value={searchParams.get('status') || ''}
            onSelect={(value) => {
              updateSearchParams('status', value);
            }}
          />
        ),
        span: 2,
      },
      {
        key: '6',
        label: '기타 메모',
        children: <Input onChange={handleMemoChange} value={memo} />,
        span: 4,
      },
    ];

    return filters;
  }, [groups, plans, statusList, memo, searchParams, dateRangeKey]);

  return (
    <Flex vertical gap="16px">
      <Flex justify="space-between">
        <Input.Search
          style={{ width: '40%' }}
          onChange={handleSearchChange}
          placeholder="쇼핑몰 이름, 쇼핑몰 ID, 로그인 이메일"
          value={term ? term : undefined}
          defaultValue={searchParams.get('term') || undefined}
          loading={isLoading}
        />
        <Flex align="center" gap={4}>
          <Button
            icon={<FileExcelOutlined />}
            type="text"
            size="large"
            onClick={handleDownloadExcel}
          >
            엑셀 다운로드
          </Button>
          <Button
            icon={<UserAddOutlined />}
            type="text"
            size="large"
            onClick={() => {
              navigate('/create-user');
            }}
          >
            업체 계정 생성
          </Button>
          <Button
            icon={<RetweetOutlined />}
            type="text"
            size="large"
            onClick={() => {
              setTerm('');
              setMemo('');
              navigate('/users');
            }}
          />
        </Flex>
      </Flex>

      <Descriptions items={filters} bordered size="small" />
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`/user/${record.key}`);
            },
          };
        }}
        pagination={{
          total: count,
          onChange: (page) => {
            const newParams = new URLSearchParams(searchParams.toString());
            navigate({
              pathname: `/users/${page}`,
              search: newParams.toString(),
            });
          },
          showSizeChanger: false,
          current: currentPage,
        }}
      />
    </Flex>
  );
};

export default UsersPage;
