import { Modal, Tabs, TabsProps } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import UserPlan from './plan';
import UserPage from './user';
import ConfirmCreatedProductModal from '../../../components/ConfirmCreatedProductModal';
import { useGetShopAccountQuery } from '../../../generated/api/axios-client/ShopAccountControllerQuery';
import { useNotification } from '../../../hooks/notification';

type Tabs = 'user' | 'plan';

function isTab(value: string): value is Tabs {
  return ['user', 'plan'].includes(value);
}

const UserIndex: React.FC = () => {
  const [tab, setTab] = useState<Tabs>('user');
  const { shopAccountId } = useParams<{ shopAccountId: string }>();
  const [modal, setModal] = useState<boolean>();
  const notification = useNotification();

  const { data } = useGetShopAccountQuery({
    shopAccountId: shopAccountId ? Number(shopAccountId) : 0,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!data.loginMail && modal === undefined) {
      setModal(true);
    }
  }, [data]);

  const onChange = (key: string) => {
    if (isTab(key)) {
      setTab(key);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: 'user',
      label: '업체 관리',
      children: <UserPage />,
    },
    {
      key: 'plan',
      label: '플랜 관리',
      children: <UserPlan />,
    },
  ];

  return (
    <>
      <Tabs activeKey={tab} items={items} onChange={onChange} />
      <Modal
        title="업체 계정 생성 확인"
        open={modal}
        onCancel={() => {
          setModal(false);
        }}
        onOk={() => {
          navigator.clipboard
            .writeText(
              `${process.env.REACT_APP_CHARLLA_HOST}/account/signup/connect/${shopAccountId}`,
            )
            .then(() => {
              notification.notify.success({
                message:
                  '클립보드에 링크가 복사되었습니다. 고객사에 전달해 주세요',
              });
              setModal(false);
            })
            .catch((err) => {
              console.error('복사 실패: ', err);
            });
        }}
        okText="확인 및 링크복사"
        cancelText="취소"
      >
        {shopAccountId && (
          <ConfirmCreatedProductModal shopAccountId={Number(shopAccountId)} />
        )}
      </Modal>
    </>
  );
};

export default UserIndex;
