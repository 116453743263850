import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';

import { ShopAccount } from '../generated/api/axios-client';

const API_HOST_URL = process.env.REACT_APP_API_HOST;

export const useGetShopAccountLazyQuery = (): [
  (shopAccountId: number) => Promise<ShopAccount | undefined>,
  ShopAccount | undefined,
  { refetch: (shopAccountId: number) => void },
] => {
  const queryClient = useQueryClient();
  const [user, setUser] = useState<ShopAccount>();

  const fetch = async (shopAccountId: number) => {
    try {
      const cachedData = queryClient.getQueryData([
        'getShopAccount',
        shopAccountId,
      ]);

      if (cachedData) {
        return cachedData;
      }

      if (user) {
        return user;
      }
      const data = (
        await axios.get(`${API_HOST_URL}/shop-account/${shopAccountId}`)
      ).data;
      setUser(data);
      queryClient.setQueryData(['getShopAccount', shopAccountId], data);

      return data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  const refetch = async (shopAccountId: number) => {
    const data = (
      await axios.get(`${API_HOST_URL}/shop-account/${shopAccountId}`)
    ).data;

    setUser(data);
    queryClient.setQueryData(['getShopAccount', shopAccountId], data);
  };

  return [fetch, user, { refetch }];
};
