import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { setAxiosFactory, setBaseUrl } from './generated/api/axios-client';
import { ACCESS_TOKEN_KEY } from './hooks/auth';
import { NotificationProvider } from './hooks/notification';
import Router from './router';

import 'antd/dist/reset.css';
import './index.css';

const queryClient = new QueryClient();

if (process.env.REACT_APP_API_HOST) {
  if (window.location.hostname === 'admin.charlla.io') {
    setBaseUrl('https://admin-api.charlla.io');
  } else if (window.location.hostname === 'dev-admin.charlla.io') {
    setBaseUrl('https://stg-admin-api.charlla.io');
  } else {
    setBaseUrl(process.env.REACT_APP_API_HOST);
  }
}

setAxiosFactory(() => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (accessToken) {
    axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return axios;
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <NotificationProvider>
        <Router />
      </NotificationProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
