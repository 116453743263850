import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Descriptions,
  DescriptionsProps,
  Flex,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Typography,
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  EditShopAccountData,
  EditShopAccountInput,
} from '../../../generated/api/axios-client';
import {
  useEditShopAccountMutation,
  useGetGroupsQuery,
  useGetPlanTypesQuery,
  useGetShopAccountQuery,
  useGetShopAccountStatusQuery,
} from '../../../generated/api/axios-client/ShopAccountControllerQuery';
import { useNotification } from '../../../hooks/notification';
import {
  formatBusinessNumber,
  formatDate,
  formatTime,
} from '../../../utils/format';

const WITHDRAW_REASON_PREVIEW = [
  '구독 요금이 너무 비싸요',
  '로드 수 초과 금액이 너무 비싸요',
  '찰나의 뚜렷한 효과를 잘 모르겠어요',
  '찰나 동영상을 더 이상 사용하지 않아요',
  '비즈니스를 더 이상 운영하지 않아요',
  '기타',
];

const UserPage: React.FC = () => {
  const [editMode, setEditMode] = useState(false);
  const { shopAccountId } = useParams<{ shopAccountId: string }>();
  const notification = useNotification();
  const { data: groups } = useGetGroupsQuery();
  const { data: plans } = useGetPlanTypesQuery();
  const { data: statuses } = useGetShopAccountStatusQuery();

  const { data, refetch } = useGetShopAccountQuery({
    shopAccountId: shopAccountId ? Number(shopAccountId) : 0,
  });

  const editShopAccountMutation = useEditShopAccountMutation({
    onSuccess: () => {
      refetch();
      setEditMode(false);
    },
    onError: () => {
      notification.notify.error({
        message: '업체의 서비스계정 수정에 실패했습니다.',
      });
    },
  });

  const [editGroupId, setEditGroupId] = useState<number>();
  const [editPlanId, setEditPlanId] = useState<number>();
  const [editStatusId, setEditStautsId] = useState<number>();
  const [editWithdrawReason, setEditWithdrawReason] = useState<string>();
  const [editExtraWithdrawReason, setEditExtraWithdrawReason] =
    useState<string>();
  const [editMemo, setEditMemo] = useState<string>();

  const items = useMemo<DescriptionsProps['items']>(() => {
    if (!data) {
      return [];
    }

    const description: DescriptionsProps['items'] = [
      {
        key: 1,
        label: '업체 이름',
        children: <p>{data.mallName}</p>,
        span: 2,
      },
      {
        key: 2,
        label: '쇼핑몰 ID',
        children: <p>{data.mallId}</p>,
        span: 2,
      },
      {
        key: 3,
        label: '대표 로그인 이메일',
        children: <p>{data.loginMail}</p>,
        span: 2,
      },
      {
        key: 4,
        label: '가입일',
        children: <p>{data.createdAt}</p>,
        span: 2,
      },
      {
        key: 5,
        label: '그룹',
        children: editMode ? (
          <Select
            placeholder="그룹 선택"
            style={{ width: '240px' }}
            value={editGroupId}
            onSelect={(value) => {
              setEditGroupId(value);
            }}
          >
            {groups?.map((group) => (
              <Select.Option key={`group_${group.id}`} value={group.id}>
                {group.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <p>{data.group?.name}</p>
        ),
        span: 2,
      },
      {
        key: 6,
        label: '플랜',
        children: editMode ? (
          <Select
            placeholder="플랜 선택"
            style={{ width: '240px' }}
            value={editPlanId}
            onSelect={(value) => {
              setEditPlanId(value);
            }}
          >
            {plans?.map((plan) => (
              <Select.Option key={`plan_${plan.id}`} value={plan.id}>
                {plan.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <p>{data.plan.name}</p>
        ),
        span: 2,
      },
      {
        key: 7,
        label: '최근 결제일',
        children: <p>{data.latestPaidAt}</p>,
        span: 2,
      },
      {
        key: 8,
        label: '계정 상태',
        children: editMode ? (
          <Select
            placeholder="상태 선택"
            style={{ width: '240px' }}
            value={editStatusId}
            onSelect={(value) => {
              setEditStautsId(value);
            }}
          >
            {statuses?.map((status) => (
              <Select.Option key={`status_${status.id}`} value={status.id}>
                {status.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <p>{data.status?.name}</p>
        ),
        span: 2,
      },
      {
        key: 9,
        label: '해지 이유',
        children: editMode ? (
          <Flex vertical>
            <Radio.Group
              onChange={(e: RadioChangeEvent) => {
                setEditWithdrawReason(e.target.value);
              }}
              value={editWithdrawReason}
            >
              <Space direction="vertical">
                <Radio value={WITHDRAW_REASON_PREVIEW[0]}>
                  구독 요금이 너무 비싸요
                </Radio>
                <Radio value={WITHDRAW_REASON_PREVIEW[1]}>
                  로드 수 초과 금액이 너무 비싸요
                </Radio>
                <Radio value={WITHDRAW_REASON_PREVIEW[2]}>
                  찰나의 뚜렷한 효과를 잘 모르겠어요
                </Radio>
                <Radio value={WITHDRAW_REASON_PREVIEW[3]}>
                  찰나 동영상을 더 이상 사용하지 않아요
                </Radio>
                <Radio value={WITHDRAW_REASON_PREVIEW[4]}>
                  비즈니스를 더 이상 운영하지 않아요
                </Radio>
                <Radio value={WITHDRAW_REASON_PREVIEW[5]}>기타</Radio>
              </Space>
            </Radio.Group>
            {editWithdrawReason === WITHDRAW_REASON_PREVIEW[5] && (
              <>
                <Typography.Text className="mt-4">기타의견</Typography.Text>
                <Input.TextArea
                  value={editExtraWithdrawReason}
                  onChange={(e) => setEditExtraWithdrawReason(e.target.value)}
                />
              </>
            )}
          </Flex>
        ) : (
          <p>{data.withdrawReason}</p>
        ),
        span: 2,
      },
      {
        key: 10,
        label: '사업자 등록번호',
        children: <p>{formatBusinessNumber(data.registrationNumber)}</p>,
        span: 2,
      },
      {
        key: 11,
        label: '쇼핑몰 도메인',
        children: <p>{data.mallUrl}</p>,
        span: 2,
      },
      {
        key: 12,
        label: '기타 메모',
        children: editMode ? (
          <Input.TextArea
            value={editMemo}
            onChange={(e) => setEditMemo(e.target.value)}
          />
        ) : (
          <p>{data.memo}</p>
        ),
        span: 2,
      },
    ];

    return description;
  }, [
    data,
    editMode,
    editGroupId,
    editPlanId,
    editStatusId,
    editWithdrawReason,
    editExtraWithdrawReason,
    editMemo,
  ]);

  const onClickEdit = () => {
    if (!shopAccountId) {
      return;
    }

    const current = new Date();

    const createdAt = `${formatDate(current)} ${formatTime(current)}`;

    editShopAccountMutation.mutate(
      new EditShopAccountInput({
        shopAccountId: Number(shopAccountId),
        data: new EditShopAccountData({
          groupId: editGroupId,
          status: editStatusId,
          plan: editPlanId,
          withdrawReason:
            editWithdrawReason &&
            editWithdrawReason !== WITHDRAW_REASON_PREVIEW[5]
              ? `${createdAt}|${editWithdrawReason}`
              : editExtraWithdrawReason &&
                  editWithdrawReason === WITHDRAW_REASON_PREVIEW[5]
                ? `${createdAt}|${editExtraWithdrawReason}`
                : undefined,
          memo: editMemo,
        }),
      }),
    );
  };

  const handleEditInit = () => {
    if (!data) {
      return;
    }

    setEditGroupId(data.group?.id);
    setEditPlanId(data.plan.id);
    setEditStautsId(data.status?.id);

    if (data.withdrawReason) {
      if (data.withdrawReason.includes('|')) {
        const [, reason] = data.withdrawReason.split('|');
        if (WITHDRAW_REASON_PREVIEW.includes(reason)) {
          setEditWithdrawReason(reason);
        } else {
          setEditWithdrawReason(WITHDRAW_REASON_PREVIEW[5]);
          setEditExtraWithdrawReason(reason);
        }
      }
    } else {
      setEditWithdrawReason(data.withdrawReason);
    }
    setEditMemo(data.memo);
  };

  useEffect(() => {
    handleEditInit();
  }, [data]);

  return (
    <>
      <Flex className="w-full" vertical>
        <Descriptions
          title={
            <Flex align="center" justify="space-between">
              <Typography.Title>{data?.mallName}</Typography.Title>
              <Button
                icon={editMode ? <CloseOutlined /> : <EditOutlined />}
                onClick={() => setEditMode(!editMode)}
              >
                {editMode ? '변경모드 나가기' : '변경'}
              </Button>
            </Flex>
          }
          items={items}
          bordered
          style={{ width: '100%' }}
        />
        {editMode && (
          <Flex gap={8} className="mt-4" justify="flex-end">
            <Button
              onClick={() => {
                handleEditInit();
                setEditMode(false);
              }}
            >
              실행 취소
            </Button>
            <Button type="primary" onClick={onClickEdit}>
              저장
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default UserPage;
