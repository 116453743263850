import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { useNotification } from './hooks/notification';
import LoginPage from './pages/login';
import RootPage from './pages/root';
import CreateUserPage from './pages/user/create-user';
import UserPage from './pages/user/user';
import UsersPage from './pages/user/users';
import WithdrawUserPage from './pages/user/withdraw-user';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage />,
    children: [
      {
        path: '/users',
        element: <UsersPage />,
      },
      {
        path: '/users/:page',
        element: <UsersPage />,
      },
      {
        path: '/user/:shopAccountId',
        element: <UserPage />,
      },
      {
        path: '/create-user',
        element: <CreateUserPage />,
      },
      {
        path: 'withdraw-user',
        element: <WithdrawUserPage />,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
]);

const Router: React.FC = () => {
  const notification = useNotification();
  return (
    <>
      {notification.contextHolder}
      <RouterProvider router={router} />
    </>
  );
};

export default Router;
