import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Card, Flex, Menu, theme, Typography } from 'antd';
import Sider from 'antd/es/layout/Sider';
import Layout, { Content, Header } from 'antd/es/layout/layout';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import BreadCrumb, { pathMap } from '../components/BreadCrumb';
import { useAuth } from '../hooks/auth';

const RootPage: React.FC = () => {
  const { isLogin, logout } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLogin === false) {
      navigate('/login');
    }
  }, [isLogin, navigate]);

  if (!isLogin) {
    return null;
  }

  return (
    <Layout className="min-h-screen">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['user', 'statistic', 'operation']}
          defaultOpenKeys={['user', 'statistic', 'operation']}
          selectedKeys={[location.pathname]}
          items={[
            {
              key: 'user',
              icon: <UserOutlined />,
              label: '업체 관리',
              children: [
                {
                  key: '/users',
                  label: pathMap['/users'],
                  onClick: () => {
                    navigate('/users');
                  },
                },
                {
                  key: '/create-user',
                  label: pathMap['/create-user'],
                  onClick: () => {
                    navigate('/create-user');
                  },
                },
                // {
                //   key: '/withdraw-user',
                //   label: pathMap['/withdraw-user'],
                //   onClick: () => {
                //     navigate('/withdraw-user');
                //   },
                // },
              ],
            },
            // {
            //   key: 'statistic',
            //   icon: <LineChartOutlined />,
            //   label: '통계',
            //   children: [
            //     {
            //       key: 'dashboard',
            //       label: '대시보드',
            //     },
            //     {
            //       key: 'sales',
            //       label: '매출',
            //     },
            //     {
            //       key: 'use',
            //       label: '사용량',
            //     },
            //   ],
            // },
            // {
            //   key: 'operation',
            //   icon: <ControlOutlined />,
            //   label: '운영',
            //   children: [
            //     {
            //       key: 'admin',
            //       label: '관리자 계정 관리',
            //     },
            //     {
            //       key: 'group',
            //       label: '그룹 관리',
            //     },
            //     {
            //       key: 'cron',
            //       label: '크론 관리',
            //     },
            //     {
            //       key: 'main',
            //       label: '메일함',
            //     },
            //   ],
            // },
          ]}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Flex justify="space-between" align="center" className="h-full p-4">
            <Flex className="h-full text-center" align="center" gap={8}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 36,
                  height: 36,
                }}
              />
            </Flex>

            <Button
              type="text"
              icon={<LogoutOutlined />}
              style={{
                fontSize: '16px',
              }}
              onClick={() => {
                logout();
              }}
            >
              로그아웃
            </Button>
          </Flex>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
          }}
        >
          <BreadCrumb />
          <Content
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              padding: 24,
              marginTop: 24,
            }}
          >
            {location.pathname === '/' && (
              <Card style={{ textAlign: 'center', marginBottom: 24 }}>
                <Typography.Title level={2}>
                  찰나 관리자페이지에 오신 것을 환영합니다
                </Typography.Title>
                <Typography.Paragraph>
                  여기에서 관리할 수 있는 다양한 기능을 탐색해 보세요.
                </Typography.Paragraph>
              </Card>
            )}
            <div className="w-full h-[calc(100vh-220px)] overflow-x-auto overflow-y-auto">
              <Outlet />
            </div>
          </Content>
        </Content>
      </Layout>
    </Layout>
  );
};

export default RootPage;
